import Vue from 'vue';
import VueRouter from 'vue-router';
import VueDeviceDetector from 'vue-device-detector'; // 引入 VueDeviceDetector

// 注册插件
Vue.use(VueRouter);
Vue.use(VueDeviceDetector);

// 导入各个页面组件
import Login from '../views/pages/Login';
import Register from "../views/pages/Register";
import Home from "../views/Home";
import Files from "../views/modules/filePages/files";
import Video from "../views/modules/video/video";
import OfflineDownload from "../views/modules/filePages/offlineDownload";
import ShareFiles from "../views/modules/sharePages/shareFiles";
import SharedFiles from "../views/modules/sharePages/sharedFiles";
import ChangePassword from "../views/pages/ChangePassword";
import personalCenter from "../views/pages/personalCenter";
import CollectFiles from "../views/modules/collectFilesPages/collectFiles";
import NodePages from "../views/modules/notePages/nodePages";
import RecyclePage from "../views/modules/recycle/recyclePage";
import VersionPage from "../views/modules/versionPages/versionPage";
import SettingPage from "../views/modules/settingPage/SettingPage";

// 移动端页面
import Mobile from "../views/Mobile";
import MobileFiles from "../views/modules/mobilePages/MobileFiles";
import UploadList from "../views/modules/mobilePages/UploadList";

// 判断是否为移动端设备
function isMobile() {
	return Vue.prototype.$device && Vue.prototype.$device.mobile;
}

// 定义路由
const routes = [{
		path: '/register',
		name: 'Register',
		component: Register,
		hidden: true,
	},
	{
		path: '/',
		name: 'Login',
		component: Login,
		hidden: true,
	},
	{
		path: '/changePassword',
		name: 'ChangePassword',
		component: ChangePassword,
		hidden: true,
	},
	{
		path: '/personalCenter',
		name: 'personalCenter',
		component: personalCenter,
		hidden: true,
	},
	{
		path: '/uploadList',
		name: 'UploadList',
		component: UploadList,
		hidden: true,
	},
	{
		path: '/home',
		name: '我的网盘',
		component: isMobile() ? Mobile : Home,
		redirect: '/files', // 默认选中子路由全部文件为主页
		children: [{
				path: '/files',
				name: '全部文件',
				iconCls: 'el-icon-files',
				component: isMobile() ? MobileFiles : Files,
			},
			{
				path: '/video',
				name: '影视仓库',
				iconCls: 'el-icon-video-camera',
				component: Video,
			},
			{
				path: '/shareFiles',
				name: '我的分享',
				iconCls: 'el-icon-connection',
				component: ShareFiles,
			},
			// {
			// 	path: '/sharedFiles',
			// 	name: '共享文件',
			// 	iconCls: 'el-icon-connection',
			// 	component: SharedFiles
			// },
			// {
			// 	path: '/collectFiles',
			// 	name: '收集文件',
			// 	iconCls: 'el-icon-folder-add',
			// 	component: CollectFiles
			// },
			{
				path: '/test',
				name: '笔记管理',
				iconCls: 'el-icon-tickets',
				component: NodePages
			},
			// {
			// 	path: '/offlineDownload',
			// 	name: '离线下载',
			// 	iconCls: 'el-icon-folder-add',
			// 	component: OfflineDownload
			// },
			{
				path: '/recycle',
				name: '回收站',
				iconCls: 'el-icon-delete',
				component: RecyclePage,
			},
			{
				path: '/version',
				name: '版本记录',
				iconCls: 'el-icon-warning-outline',
				component: VersionPage,
			},
			// {
			// 	path: '/setting',
			// 	name: '系统设置',
			// 	iconCls: 'el-icon-setting',
			// 	component: SettingPage,
			// },
		],
	},
];

// 创建路由实例
const router = new VueRouter({
	routes,
});

export default router;