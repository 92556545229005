import config from './config'
import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "../utils/http-request";
import MessageBox from '@/components/message-box'
import result from "element-ui/packages/result";
import {
	Message
} from "element-ui";
import router from "../router";
import {
	upload
} from "./service";
import axios from "axios";

//获取目录下所以文件列表
export function getFileList(dataForm, callback) {
	postRequest(config.url.file.fileList, dataForm, callback);
}

//通过条件查询文件列表
export function getFileFindList(dataForm, callback) {
	postRequest(config.url.file.findList, dataForm, callback);
}
//重命名
export function rename(dataForm, callback) {
	postRequest(config.url.file.rename, dataForm, callback);
}

//新建文件夹
export function createFolder(dataForm, callback) {
	postRequest(config.url.file.createFolder, dataForm, callback);
}

//判断文件是否已经存在
export function checkIsHave(dataForm, callback) {
	postRequest(config.url.file.checkIsHave, dataForm, callback);
}

//删除文件
export function deleteFile(dataForm, callback) {
	postRequest(config.url.file.deleteFile, dataForm, callback);
}

/**
 * 计算文件的上传速度和剩余时间
 */
export function countingSpeed(item) {
	const nowTime = new Date().getTime();

	// 初始化 item 的状态
	if (!item.metrics) {
		item.metrics = {
			lastTime: nowTime,
			lastSize: 0,
			lastCountTime: 0,
		};
	}

	// 检查是否距离上次计算已经过了一秒
	if (nowTime - item.metrics.lastCountTime < 1000) {
		return null; // 还没到时间，不计算
	}

	const intervalTime = (nowTime - item.metrics.lastTime) / 1000+1; // 转换为秒
	const intervalSize = item.uploadedSize - item.metrics.lastSize;

	// 更新状态以便下次计算
	item.metrics.lastTime = nowTime;
	item.metrics.lastSize = item.uploadedSize;
	item.metrics.lastCountTime = nowTime;

	// 计算速度
	let speed = intervalSize / intervalTime || 0; // 避免 NaN
	
	let leftTime = 0;

	if (speed < 0) speed = -speed; // 确保速度为正

	if (item.uploadedSize > 0 && item.sizeInBytes > 0) {
		leftTime = (item.sizeInBytes - item.uploadedSize) / speed;
	} else {
		speed = 0; // 没有有效的上传数据
		leftTime = 0; // 不计算剩余时间
	}

	speed = (speed / 1024 / 1024).toFixed(2); // 以 MB/s 显示

	// 将剩余时间转换为天、小时、分钟和秒
	let leftTimeStr = "-";
	if (leftTime > 0) {
		const days = Math.floor(leftTime / 86400); // 86400秒 = 1天
		const hours = Math.floor((leftTime % 86400) / 3600); // 3600秒 = 1小时
		const minutes = Math.floor((leftTime % 3600) / 60); // 60秒 = 1分钟
		const seconds = Math.floor(leftTime % 60);

		// 动态构建时间字符串
		leftTimeStr = '';
		if (days > 0) leftTimeStr += `${days}天`;
		if (hours > 0) leftTimeStr += `${hours}小时`;
		if (minutes > 0) leftTimeStr += `${minutes}分`;
		leftTimeStr += `${seconds}秒`; // 秒总是显示
	} else {
		leftTimeStr = '计算中...'; // 提示用户正在计算
	}

	return {
		speed: speed > 0 ? speed + " MB/s" : "正在计算...",
		leftTimeStr,
	};
}


function formatSeconds(value) {
	var secondTime = parseInt(value) / 1000; // 秒
	var minuteTime = 0; // 分
	var hourTime = 0; // 小时
	if (secondTime >= 60) {
		minuteTime = parseInt(secondTime / 60);
		secondTime = parseInt(secondTime % 60);
		if (minuteTime >= 60) {
			hourTime = parseInt(minuteTime / 60);
			minuteTime = parseInt(minuteTime % 60);
		}
	}
	var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime));

	if (minuteTime > 0) {
		result = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + ":" + result;
	}
	if (hourTime > 0) {
		result = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + ":" + result;
	}
	return result;
}

//获取离线下载文件列表
export function getOfflineFilesList(dataForm, callback) {
	postRequest(config.url.file.offlineFileList, dataForm, callback);
}