<template>
	<div class="mobile-files-main-list">
		<!-- 顶部栏 -->
		<div class="longPressDiv" v-if="isSelection">
			<div class="cancelSelect" @click="cancelSelection">取消</div>
			<div class="selectSize">已选中{{ multipleSelection.length }}个文件</div>
			<div class="allSelect" @click="allSelection">全选</div>
		</div>
		<div class="longPressDiv" v-else>
			<!-- 上传控件 -->
			<input id="upload-input" type="file" ref="uploadInput" name="file" style="display: none;"
				@change="uploadFile" multiple />
			<div>
				<span>
					<i class="el-icon-arrow-left" style="margin-right: 5px;" v-if="currentPathName != '/'"
						@click="lastPage"></i>
					{{ currentPath }}
				</span>
			</div>
			<div class="moblie-more">
				<div @click="transmissionList">
					<i class="el-icon-sort" style="font-size: 24px; margin-right: 10px;"></i>
				</div>
				<el-dropdown class="userInfo" style="margin-top: 10px;" @command="commandHandler">
					<div style="text-align: center;">
						<i class="el-icon-circle-plus-outline" style="font-size: 24px; margin-right: 10px;"></i>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="selectFile">选择</el-dropdown-item>
						<el-dropdown-item command="upload">上传文件</el-dropdown-item>
						<el-dropdown-item command="create">新建文件夹</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown class="userInfo" @command="commandHandler">
					<div style="text-align: center;">
						<div>
							<img width="24px" height="24px" style="border-radius: 50%;" v-if="photo" :src="photo" /><img
								v-else src="/cloud/image/avatar.png" />
						</div>
						<div>
							<i class="userName">{{ username }}</i>
						</div>
					</div>
					<el-dropdown-menu slot="dropdown" hide-on-click="true">
						<el-dropdown-item command="personalCenter">个人中心</el-dropdown-item>
						<el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
						<el-dropdown-item command="logout">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>

		<!-- 工具栏 -->
		<div class="m-file-toolbar__wrapper" v-if="isSelection">
			<div class="btn-group__wrapper" style="float: left">
				<el-button-group class="file-button-group">
					<input id="upload-input2" type="file" ref="uploadInput" name="file" style="display: none;"
						@change="uploadFile" webkitdirectory directory multiple />
					<!-- <el-button v-if="selectStatus == 0 && pasteStatus == 0" size="small" icon="el-icon-upload2"
						@click="toUpload">上传文件</el-button>
					<el-button size="small" icon="el-icon-upload" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="toUploadDirectory">上传文件夹</el-button>
					<el-button size="small" icon="el-icon-folder-add" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="createFolderConfirm">新建文件夹</el-button>
					<el-button size="small" icon="el-icon-upload" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="offlineDownload">离线下载</el-button> -->
					<el-button size="small" icon="el-icon-folder-add"
						v-if="selectStatus == 1 && previewStatus == 1 && pasteStatus == 0"
						@click="previewImg">预览</el-button>
					<el-button size="small" icon="el-icon-video-camera"
						v-if="selectStatus == 1 && pasteStatus == 0 && multipleSelection.length == 1 && multipleSelection[0].isDir == 0 && multipleSelection[0].category == 1 && !multipleSelection[0].description"
						@click="addToMovie">添加到影视库</el-button>
					<el-button size="small" icon="el-icon-video-camera"
						v-if="selectStatus == 1 && pasteStatus == 0 && multipleSelection.length == 1 && multipleSelection[0].isDir == 0 && multipleSelection[0].category == 1 && multipleSelection[0].description"
						@click="removeOfMovie">移出影视库</el-button>
					<el-button size="small" icon="el-icon-share" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="centerDialogVisible = true">分享</el-button>
					<el-button size="small" icon="el-icon-download" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="downloadFile">下载</el-button>
					<el-button size="small" icon="el-icon-edit"
						v-if="selectStatus == 1 && renameStatus == 1 && pasteStatus == 0"
						@click="rename">重命名</el-button>
					<el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="moveFiles">移动</el-button>
					<el-button size="small" icon="el-icon-delete" v-if="pasteStatus == 1"
						@click="cencleMove">取消移动</el-button>
					<el-button size="small" icon="el-icon-delete" v-if="pasteStatus == 1"
						@click="pasteFiles">粘贴</el-button>
					<el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="deleteFiles">删除</el-button>
					<el-button size="small" icon="el-icon-cencle" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="cancelSelection">取消选中</el-button>
				</el-button-group>
			</div>
		</div>

		<!-- 文件列表 -->

		<div class="nd-table is-show-header">
			<template>
				<div class="box">
					<div class="wrap">
						<div class="imgContent" @click="showImgViewer"></div>
						<!-- 图片预览 -->
						<el-image-viewer v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgList" />
						<div class="videoContent" @click="playVideo()"></div>
						<!-- 视频预览 -->
						<el-dialog :title="videoName" :visible.sync="dialogPlay" width="70%" @close="closeDialog">
							<video :src="videoUrl" autoplay class="video" style="max-height: calc(100vh - 30vh)"
								width="100%" controls="controls"></video>
						</el-dialog>
					</div>

					<div id="drop-area" :class="dropActive ? 'drop-active' : ''" style="height: 100%;">
						<!-- 列表布局 -->
						<div class="tableBox" v-if="iconStatus == 0" v-loading="uploadLoading"
							element-loading-text="正在加载本地文件中..." element-loading-spinner="el-icon-loading"
							element-loading-background="rgba(0, 0, 0, 0.8)">
							<div class="grid">
								<el-table :data="fileDataList" style="width: 100%" height="100%"
									@selection-change="handleSelectionChange">
									<el-table-column type="selection" width="50"></el-table-column>
									<el-table-column width="40">
										<template slot-scope="scope">
											<img style="height: 23px; width: 23px; position: absolute; top:50%; transform: translateY(-50%);"
												:src="getImgSrc(scope.row)" alt="这是图片" @dblclick="openDir(scope.row)" />
										</template>
									</el-table-column>
									<el-table-column prop="fileName" label="文件名称" min-width="240"
										show-overflow-tooltip></el-table-column>
									<el-table-column prop="fileSize" label="大小" width="240">
										<template slot-scope="scope">
											{{ formatFileSize(scope.row.fileSize) }}
										</template>
									</el-table-column>
									<el-table-column prop="suffix" label="扩展名" width="240"></el-table-column>
									<el-table-column label="创建日期" width="240">
										<template slot-scope="scope">{{ scope.row.createTime }}</template>
									</el-table-column>
									<el-table-column label="修改日期" width="240">
										<template slot-scope="scope">{{ scope.row.lastModifyTime }}</template>
									</el-table-column>
								</el-table>
							</div>
						</div>

						<!-- 图标布局 -->
						<div class="tableBox" v-if="iconStatus == 1" v-loading="uploadLoading"
							element-loading-text="正在加载本地文件中..." element-loading-spinner="el-icon-loading"
							element-loading-background="rgba(0, 0, 0, 0.8)">
							<div class="mobile-grid">
								<div class="grid-item-mobile" :class="{ checked: item.checked }"
									v-for="(item, index) in fileDataList" @click="onClickFile(item, index)">
									<img :src="getImgSrc(item)" />
									<span :title="item.fileName">{{ item.fileName }}</span>
									<!-- 如果是视频，则添加播放按钮 -->
									<div v-if="item.category === 1" class="m-play-button-overlay">
										<img style="width" src="/cloud/image/play.png" alt="Play Button" />
									</div>
									<!-- 如果已添加到影视库，则显示标识 -->
									<div v-if="item.description" class="movie-overlay">
										<svg t="1728482595265" class="icon" viewBox="0 0 1024 1024" version="1.1"
											xmlns="http://www.w3.org/2000/svg" p-id="6610" width="20" height="20">
											<path
												d="M928 960l-832 0C43.072 960 0 916.928 0 864l0-704C0 107.072 43.072 64 96 64l832 0C980.928 64 1024 107.072 1024 160l0 704C1024 916.928 980.928 960 928 960zM96 128C78.336 128 64 142.336 64 160l0 704C64 881.6 78.336 896 96 896l832 0c17.6 0 32-14.4 32-32l0-704C960 142.336 945.6 128 928 128L96 128z"
												fill="#999999" p-id="6611"></path>
											<path d="M64 320.064l895.872 0 0 64-895.872 0 0-64Z" fill="#999999"
												p-id="6612"></path>
											<path d="M192 128.064l64 0 0 192-64 0 0-192Z" fill="#999999" p-id="6613">
											</path>
											<path d="M384 128.064l64 0 0 192-64 0 0-192Z" fill="#999999" p-id="6614">
											</path>
											<path d="M576 128.064l64 0 0 192-64 0 0-192Z" fill="#999999" p-id="6615">
											</path>
											<path d="M768 128.064l64 0 0 192-64 0 0-192Z" fill="#999999" p-id="6616">
											</path>
											<path
												d="M438.208 519.36C400.32 500.416 384 519.552 384 561.984l0 153.728c0 42.368 16.32 61.504 54.208 42.496l162.88-85.12c37.888-19.008 41.472-49.92 3.712-68.672L438.208 519.36z"
												fill="#999999" p-id="6617"></path>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>

		<!-- <div class="mobile-memory" style="padding: 10px; text-align: left; font-size: 0.9rem;">
				<span>我的空间：总容量2TB</span>  <span class="">已使用{{ usedMemoryG }}G</span>
				<el-progress :percentage="percentage" :text-inside="true" :show-text="false" :stroke-width="12"  :color="color"></el-progress>			
		</div> -->
		<!-- 分享选项弹框 -->
		<el-dialog title="分享" :visible.sync="centerDialogVisible" width="400px" style="border-radius: 5px;" center>
			<div class="wp-share-file">
				<span>有效期：</span>
				<el-select v-model="validity" placeholder="请选择">
					<el-option v-for="item in validityData" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
			</div>
			<div class="wp-share-file">
				<span>是否加密：</span>
				<el-switch v-model="isEncrypt"></el-switch>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="shareFiles('share_list3')">分享相册</el-button>
				<el-button type="primary" @click="shareFiles('share_list')">直接分享</el-button>
			</span>
		</el-dialog>

		<!-- 分享结果弹框 -->
		<el-dialog title="分享" :visible.sync="shareFilesDialogVisible" width="80%" center style="background: #fff;">
			<div class="wp-share-file">
				<span style="color: #3a8ee6"><i class="el-icon-circle-check"></i>成功创建分享链接</span>
			</div>
			<div class="wp-share-file">
				<el-input placeholder="分享链接" v-model="shareLink" :disabled="true"></el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>提取码：</span>
				<el-input v-model="verificationCode" :disabled="true"></el-input>
			</div>
			<div class="wp-share-file"
				v-if="isEncrypt != true && multipleSelection.length === 1 && multipleSelection[0].isDir === 0">
				<span>shell下载命令：</span>
				<el-input v-model="verificationCode" :disabled="true"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="copyShareLink" v-if="isEncrypt === true">复制链接及提取码</el-button>
				<el-button type="primary" @click="copyShareLink1"
					v-if="isEncrypt != true"><strong>复制链接</strong></el-button>
			</span>
		</el-dialog>

		<!-- 文件上传列表 -->
		<div class="mobile-upload-list-dialog" id="uploadListDialog">
			<div class="dialog-header">
				<div @click="clearFileList">
					<span>清空</span>
				</div>
				<div>
					<span>上传列表</span>
					<i class="el-icon-arrow-down" id="hideButton" @click="transmissionList" style="display: none"></i>
					<i class="el-icon-arrow-up" id="displayButton" @click="transmissionList"></i>
				</div>
				<div style="margin-right: 10px;">
					<span>{{ completeFileSize }} / {{ totalSize }}</span>
				</div>


			</div>
			<div class="dialog-body">
				<!-- 上传进度列表 -->
				<div class="item">
					<transition-group name="list-move" tag="div">
						<div class="mobile-file-info" v-for="(item, index) in fileList" :key="item.uid">
							<div class="img">
								<img :src="item.previewUrl" width="20" height="20" />
							</div>
							<div class="file-name">
								<div class="">
									<span>{{ item.name }}</span>
								</div>
								<!-- 上传进度条，上传完成后隐藏 -->
								<div class="el-progress file-progress" v-if="!item.isCompleted"
									style="float: left; margin: 4px 0;">
									<el-progress :percentage="item.progress" :show-text="false"
										:stroke-width="4"></el-progress>
								</div>
								<div class="mobile-size">
									<div class="mobile-size-time">
										<!-- 上传过程中显示已上传大小，完成后显示总大小 -->
										<span v-if="!item.isCompleted" class="mobile-size-load">
											{{ formatFileSize(item.uploadedSize) }}/{{ item.size }}
											<!-- 上传完成后不显示剩余时间 -->
											<span v-if="!item.isCompleted">{{ item.leftTime }}</span>
										</span>
										<span v-else>{{ item.size }}</span>
									</div>
									<div class="mobile-size-speed">
										<span>{{ item.speed }}</span>
									</div>
								</div>
							</div>
							<div class="operation">
								<i class="el-icon-close" @click="handleRemove(item)"></i>
							</div>
						</div>

					</transition-group>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 导入所需模块和组件
	import MessageBox from '@/components/message-box';
	import {
		countingSpeed,
		deleteFile,
		getFileList,
		getFileFindList,
		createFolder,
		rename,
	} from '../../../api/file';
	import {
		shareFiles
	} from '../../../api/share';
	import {
		addToMovie,
		removeOfMovie
	} from '../../../api/movie';
	import {
		getRequest,
		postRequest,
		getContentLong,
		singleUpload,
		enqueueUploadTask,
	} from '../../../utils/http-request';
	import config from '../../../api/config';
	import axios from 'axios';
	import {
		upload
	} from '../../../api/service';
	import {
		Message
	} from 'element-ui';
	import router from '../../../router';
	import qs from 'qs';
	import Utils from '../../../assets/util';
	import {
		jsonp
	} from 'vue-jsonp';

	import {
		getMemory,
		getUserInfo,
		logout
	} from "../../../api/auth";

	export default {
		name: 'files',
		data() {
			return {
				// 当前访问路径
				currentPathName: sessionStorage.getItem('path') || '/',
				currentPath: '根目录',
				multipleSelection: [], // 选中的文件
				selectStatus: 0, // 选择状态：0未选择，1已选择
				pasteStatus: 0, // 粘贴状态：0默认，1粘贴
				pasteFileIdList: [], // 需要粘贴的文件ID列表
				previewStatus: 0, // 是否显示预览按钮
				renameStatus: 0, // 是否显示重命名按钮
				iconStatus: 1, // 布局状态：0列表，1图标
				dataForm: {
					path: this.currentPathName,
				},
				fileDataList: [], // 文件数据列表
				fileList: [], // 上传的文件列表
				uploadState: false, // 上传状态
				loading: false, // 加载状态
				complete: false, // 上传完成状态
				isSelection: false, // 是否处于选择状态
				selectedState: [], // 已选中文件的ID列表
				uploadLoading: false, // 上传加载动画
				fileDetail: [], // 文件详情
				imgViewerVisible: false, // 图片预览可见性
				imgList: [], // 图片列表
				dialogPlay: false, // 视频播放对话框可见性
				videoName: '', // 当前预览的视频名称
				videoUrl: '', // 预览视频的地址
				dropActive: false, // 是否处于拖拽上传状态
				centerDialogVisible: false, // 分享弹窗可见性
				validity: 1, // 分享有效期
				validityData: [{
						value: 1,
						label: '1天'
					},
					{
						value: 7,
						label: '7天'
					},
					{
						value: 30,
						label: '30天'
					},
					{
						value: 365,
						label: '1年'
					},
					{
						value: 36500,
						label: '永久'
					},
				],
				isEncrypt: true, // 分享是否加密
				shareFilesDialogVisible: false, // 分享结果弹窗可见性
				offlineDownloadDialogVisible: false, // 离线下载弹窗可见性
				offlineDownloadLink: '', // 离线下载链接
				offlineDownloadName: '', // 离线下载文件名称
				offlineDownloadSize: '', // 离线下载文件大小
				shareLink: '', // 分享链接
				verificationCode: '', // 提取码
				ignoreList: ['.DS_Store', '.svn', '.git'], // 需要过滤的文件名称列表
				username: '', // 用户名
				photo: '', // 用户头像
				usedMemoryB: 0,
				usedMemoryG: 0,
				percentage: 0,
				color: '#409eff',
				debounceTimer: null, //防抖定时器
				completeFileSize: 0, //完成上传文件数量
			};
		},
		components: {
			'el-image-viewer': () => import('../../../components/image/src/image-viewer'),
		},
		mounted() {
			// 初始化文件列表和用户信息
			this.initGetFileList();
			this.initGetUserInfo();
			this.initGetMemory();
			//监听更新容量
			Utils.$on('getMemory', () => {
				this.initGetMemory();
			})
			// 拖拽上传相关事件监听
			let dropArea = document.getElementById('drop-area');
			dropArea.addEventListener('drop', this.dropEvent, false);
			dropArea.addEventListener('dragleave', (e) => {
				e.stopPropagation();
				e.preventDefault();
				this.dropActive = false;
			});
			dropArea.addEventListener('dragenter', (e) => {
				e.stopPropagation();
				e.preventDefault();
				this.dropActive = true;
			});
			dropArea.addEventListener('dragover', (e) => {
				e.stopPropagation();
				e.preventDefault();
				this.dropActive = true;
			});
		},
		computed: {
			// 计算已选中的文件
			selection() {
				return this.fileDataList.filter((item) => item.checked);
			},
			totalSize() {
				return this.fileList.length; // 总数
			},
		},
		created() {
			// 在组件创建时，向历史记录中添加一条记录，以便后续捕获返回操作
			window.history.pushState(null, null, location.href);
			window.addEventListener('popstate', this.handlePopState);
		},
		beforeDestroy() {
			// 移除事件监听器
			window.removeEventListener('popstate', this.handlePopState);
		},
		methods: {
			//2024-12-04 加入路由守卫,用于监听返回页面时候 返回上一级文件夹
			handlePopState() {
				console.log("1.handlePopState");
				if (this.currentPath==='根目录') {
					console.log("2.currentPath = 根目录 ");
					// 如果已经在最外层，提示用户是否退出
					const confirmLeave = window.confirm('是否要退出应用？');
					if (confirmLeave) {
						// 用户确认退出，可以在此执行退出逻辑
						window.history.go(-2); // 返回到首页或直接关闭页面
					} else {
						// 否则重新推入一条历史记录
						window.history.pushState(null, null, location.href);
					}
				} else {
					console.log("2.currentPath =  "+this.currentPath);
					// 如果不在最外层，执行返回上一级逻辑
					this.lastPage()
					window.history.pushState(null, null, location.href); // 再次推入一条历史记录
				}
			},			
			// 初始化用户信息
			initGetUserInfo() {
				const cachedUserInfo = sessionStorage.getItem('userInfo');
				if (cachedUserInfo) {
					const userInfo = JSON.parse(cachedUserInfo);
					this.username = userInfo.name;
					this.photo = userInfo.photo;
				}
			},
			// 初始化文件列表
			initGetFileList() {
				clearTimeout(this.debounceTimer); // 清除之前的定时器
				this.debounceTimer = setTimeout(() => {
					let vm = this;
					getFileList({
						path: this.currentPathName
					}, function(res) {
						if (res.code === 200 && res.success === true) {
							vm.fileDataList = res.data;
						} else {
							Message.error(res.message);
						}
					});
				}, 500); // 设置防抖时间为1000毫秒（1秒）

				// let vm = this;
				// getFileList({
				// 	path: this.currentPathName
				// }, function(res) {
				// 	if (res.code === 200 && res.success === true) {
				// 		vm.fileDataList = res.data;
				// 	} else {
				// 		Message.error(res.message);
				// 	}
				// })
			},
			initGetMemory() {
				getMemory((res) => {
					this.usedMemoryB = res.data;
					this.usedMemoryG = (this.usedMemoryB / 1024 / 1024 / 1024).toFixed(2);
					this.percentage = Number((this.usedMemoryG / 2048 * 100).toFixed(2));
				})
			},
			// 切换布局（列表或图标）
			changesIconStatus() {
				this.iconStatus = this.iconStatus === 0 ? 1 : 0;
				// 清空选中文件
				this.cancelSelection();
			},
			// 处理文件选择变化
			handleSelectionChange(val) {
				this.multipleSelection = val;
				if (val.length === 1) {
					switch (val[0].category) {
						case 1:
						case 3:
						case 4:
						case 6:
						case 8:
							this.previewStatus = 1; // 显示预览按钮
							break;
						default:
							this.previewStatus = 0;
							break;
					}
					this.renameStatus = 1;
				} else {
					this.previewStatus = 0;
					this.renameStatus = 0;
				}
				if (val.length > 0) {
					this.selectStatus = 1;
					this.fileDetail = this.multipleSelection;
					this.selectedState = val.map((item) => item.fileId);
				} else {
					this.selectStatus = 0;
					this.selectedState = [];
					this.fileDetail = [];
				}
			},
			// 全选
			allSelection() {
				this.selectStatus = 1;
				this.selectedState = this.fileDataList.map((item) => item.fileId);
				this.fileDataList.forEach((item) => {
					item.checked = true;
				});
				this.multipleSelection = [...this.fileDataList];
				this.fileDetail = this.multipleSelection;
			},
			// 取消选中
			cancelSelection() {
				this.isSelection = false;
				this.selectStatus = 0;
				this.selectedState = [];
				this.fileDetail = [];
				this.multipleSelection = [];
				this.fileDataList.forEach((item) => {
					item.checked = false;
				});
			},
			// 单击文件
			onClickFile(item, index) {
				if (this.isSelection) {
					// 如果处于选择状态，则选中/取消选中文件
					this.handleSelection(item, index);
				} else {
					// 否则打开文件或文件夹
					this.openDir(item);
				}
			},
			// 处理文件选中
			handleSelection(item, index) {
				const fileId = item.fileId;
				const i = this.selectedState.indexOf(fileId);
				if (i < 0) {
					// 未选中，添加到选中列表
					this.selectedState.push(fileId);
					this.multipleSelection.push(item);
					this.$set(item, 'checked', true);
				} else {
					// 已选中，取消选中
					this.selectedState.splice(i, 1);
					this.multipleSelection.splice(i, 1);
					this.$set(item, 'checked', false);
				}

				// 更新选择状态和按钮状态
				if (this.selectedState.length > 0) {
					this.selectStatus = 1;
					this.fileDetail = this.multipleSelection;
				} else {
					this.selectStatus = 0;
					this.fileDetail = [];
				}

				if (this.selectedState.length === 1) {
					switch (this.multipleSelection[0].category) {
						case 1:
						case 3:
						case 4:
						case 6:
						case 8:
							this.previewStatus = 1; // 显示预览按钮
							break;
						default:
							this.previewStatus = 0;
							break;
					}
					this.renameStatus = 1;
				} else {
					this.previewStatus = 0;
					this.renameStatus = 0;
				}
			},
			// 文件大小格式化
			formatFileSize(fileSize) {
				if (fileSize <= 0) {
					return '0KB';
				}
				let k = 1024,
					units = ['B', 'KB', 'MB', 'GB', 'TB'],
					i = Math.floor(Math.log(fileSize) / Math.log(k));
				return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + units[i];
			},
			getPreviewUrl(file) {
				// 获取文件类型
				let fileType = file.type;
				// 获取文件扩展名
				let extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
				// 判断文件是否为图片类型
				let isImage = fileType.startsWith('image/') || ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'icon']
					.includes(
						extension);

				// 如果是图片，生成本地预览 URL
				let previewUrl = '';
				if (isImage) {
					previewUrl = URL.createObjectURL(file);
				} else {
					previewUrl = this.getUploadImgSrc(extension);
				}
				return previewUrl;
			},
			getUploadImgSrc(extension) {
				// 根据扩展名返回对应的图标路径，用户加载还未解析的文件直接显示文件图标
				let fileType = {
					music: 'music',
					txt: 'txt',
					tar: 'zip',
					tgz: 'zip',
					gz: 'zip',
					rar: 'zip',
					zip: 'zip',
					h: 'c',
					c: 'c',
					cpp: 'c',
					hpp: 'c',
					m: 'code',
					bas: 'code',
					prg: 'code',
					cmd: 'bat',
					kt: 'code',
					rs: 'code',
					asp: 'aspx',
					htm: 'html',
					html: 'html',
					properties: 'conf',
					doc: 'doc',
					docx: 'doc',
					xls: 'xls',
					xlsx: 'xls',
					csv: 'xls',
					ppt: 'ppt',
					pdf: 'pdf',
					pptx: 'ppt',
					torrent: 'torrent',
				};
				let iconName = fileType[extension];
				console.log(JSON.stringify(extension) + ":" + iconName);
				if (!iconName) {
					iconName = 'other';
				}
				return `/cloud/image/file_type/${iconName}.png`;

			},
			// 获取文件图标路径，用于页面加载解析后的文件
			getImgSrc(item) {
				const {
					isDir,
					suffix,
					category,
					fileId,
					shared,
					description
				} = item;

				// 图标映射
				const iconMap = {
					0: suffix,
					2: 'music',
					4: 'txt',
					5: suffix,
					6: {
						tar: 'zip',
						tgz: 'zip',
						gz: 'zip',
						rar: 'zip',
						zip: 'zip',
					},
					7: 'torrent',
					8: {
						h: 'c',
						c: 'c',
						cpp: 'c',
						hpp: 'c',
						m: 'code',
						bas: 'code',
						prg: 'code',
						cmd: 'bat',
						kt: 'code',
						rs: 'code',
						asp: 'aspx',
						htm: 'html',
						properties: 'conf',
					},
					9: 'other',
					10: {
						doc: 'doc',
						docx: 'doc',
						xls: 'xls',
						xlsx: 'xls',
						csv: 'xls',
						ppt: 'ppt',
						pptx: 'ppt',
					},
				};

				// 以下类型读取缩略图
				if ([1, 3].includes(category) || ['pdf', 'ppt', 'pptx', 'webp'].includes(suffix)) {
					if (category === 1) {
						try {
							const desc = JSON.parse(description);
							if (desc && desc.images) {
								return desc.images;
							}
						} catch (e) {
							// 忽略JSON解析错误
						}
					}
					if (suffix === 'webp') {
						return config.url.file.downloadFile + fileId;
					}
					return config.url.file.getImgUrl + fileId;
				}

				// 如果是文件夹，显示文件夹图标
				if (isDir == 1) {
					return `/cloud/image/file_type/${shared ? 'share_folder' : 'folder'}.png`;
				}

				let icon = iconMap[category];

				if (typeof icon === 'object') {
					icon = icon[suffix] || suffix;
				}
				return `/cloud/image/file_type/${icon}.png`;
			},
			// 拖拽上传事件回调
			async dropEvent(e) {
				this.dropActive = false;
				e.stopPropagation();
				e.preventDefault();

				let fileList = [];
				let DirectoryEntryList = [];

				if (e.dataTransfer.items) {
					let items = Array.from(e.dataTransfer.items);
					for (let item of items) {
						let entry = item.webkitGetAsEntry ? item.webkitGetAsEntry() : item.getAsEntry();
						if (entry) {
							DirectoryEntryList.push(entry);
						}
					}

					for (let entry of DirectoryEntryList) {
						let FileTree = await this.getFileTree(entry);
						if (Array.isArray(FileTree)) {
							this.flattenArray(FileTree, fileList);
						} else {
							fileList.push(FileTree);
						}
					}
				}

				let files = [];
				fileList.forEach((fileItem) => {
					if (!this.ignoreList.includes(fileItem.name)) {
						files.push(new File([fileItem], fileItem.path));
					}
				});

				this.dropUploadFile(files);
			},
			// 递归获取文件树
			async getFileTree(item) {
				let path = item.fullPath || '';
				if (item.isFile) {
					let resFile = await this.fileSync(item);
					resFile.path = path;
					return resFile;
				} else if (item.isDirectory) {
					let dirReader = item.createReader();
					let entries = await this.readEntriesSync(dirReader);
					let dir = [];
					for (let entry of entries) {
						let childItem = await this.getFileTree(entry);
						dir.push(childItem);
					}
					return dir;
				}
			},
			// 展平数组
			flattenArray(array, result) {
				for (let item of array) {
					if (Array.isArray(item)) {
						this.flattenArray(item, result);
					} else {
						result.push(item);
					}
				}
			},
			// 获取文件对象
			fileSync(item) {
				return new Promise((resolve) => {
					item.file((res) => {
						resolve(res);
					});
				});
			},
			// 读取目录下的文件
			readEntriesSync(dirReader) {
				return new Promise((resolve) => {
					dirReader.readEntries((res) => {
						resolve(res);
					});
				});
			},
			// 拖拽上传文件
			dropUploadFile(fileList) {
				this.fileListUpload(fileList, this.currentPathName, () => {}, 0);
			},
			// 点击上传文件
			toUpload() {
				document.getElementById('upload-input').click();
			},
			// 点击上传文件夹
			toUploadDirectory() {
				document.getElementById('upload-input2').click();
			},
			// 上传文件列表
			uploadFile(event) {
				let fileList = event.target.files;
				this.fileListUpload(fileList, this.currentPathName, () => {}, 0);
			},
			// 文件上传处理
			fileListUpload(fileList, path, callback, imageIndex) {
				this.uploadLoading = true;

				let param = {
					path: path,
					filesName: Array.from(fileList).map((file) => file.name),
				};

				// 创建新的文件列表条目，包含上传状态等信息
				let newFileListEntries = Array.from(fileList).map((file) => ({
					uid: `${Date.now()}_${Math.random()}`,
					name: file.name,
					size: this.formatFileSize(file.size),
					sizeInBytes: file.size,
					speed: '',
					leftTime: '',
					progress: 0,
					uploadedSize: 0,
					isCompleted: false,
					file: file,
					previewUrl: this.getPreviewUrl(file), // 图片预览 URL
				}));

				// 将新文件追加到现有的 this.fileList 中
				this.fileList = this.fileList.concat(newFileListEntries);

				// 检查是否有同名文件
				postRequest(config.url.file.checkIsHave, param, (result) => {
					this.uploadLoading = false;
					if (result.code === 200 && parseInt(result.message) > 0) {
						MessageBox.confirm('目标包含' + result.message + ' 个同名文件', {
								confirmButtonText: '替换所有',
								cancelButtonText: '保留重复',
								type: 'info',
								center: true,
								showCancelButton: true,
							})
							.then(() => {
								// 设置上传类型为替换
								newFileListEntries.forEach((item) => (item.file.uploadType = 1));
								this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);
							})
							.catch(() => {
								// 设置上传类型为保留重复
								newFileListEntries.forEach((item) => (item.file.uploadType = -1));
								this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);
							});
					} else {
						// 没有同名文件，正常上传
						newFileListEntries.forEach((item) => (item.file.uploadType = 0));
						this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);
					}
				});
			},
			// 文件上传
			uploadFileAjax(fileItems, path) {
				if (document.getElementById('uploadListDialog').clientHeight == 0) {
					this.transmissionList();
				}

				fileItems.forEach((fileItem) => {
					if (fileItem.name == '.' || fileItem.name == '.DS_Store') {
						return;
					}

					this.loading = true;
					this.complete = false;
					this.uploading = true;

					// 获取文件临时uid			
					let fileUid = fileItem.uid;
					const updateProgressCallback = (uid, progress) => {
						let item = this.fileList.find((item) => item.uid === uid);
						if (item) {
							item.progress = progress;
							item.uploadedSize = item.sizeInBytes * (progress / 100);

							// 计算速度和剩余时间，直接将 item 传递
							const countingList = countingSpeed(item);
							if (countingList) { // 只有在计算有效时更新
								item.speed = countingList.speed;
								item.leftTime = countingList.leftTimeStr;
							}
							if (progress == 100) {
								item.speed = '加密中';
								item.leftTime = '请稍后';
							}

							this.uploadState = false;
						} else {
							console.warn('未找到匹配的文件项，UID:', uid);
						}
					};

					enqueueUploadTask(() =>
						singleUpload(
							fileItem.file,
							path,
							fileUid,
							updateProgressCallback,
							(res) => {
								let item = this.fileList.find((item) => item.uid === fileUid);
								if (item) {
									item.progress = 100;
									item.uploadedSize = item.sizeInBytes;
									item.speed = '已完成';
									item.leftTime = '-';
									item.isCompleted = true;
									// 记录完成时间
									item.completionTime = new Date().getTime();
									this.uploadState = true;

									this.loading = false;
									this.complete = true;
									this.uploading = false;
									this.completeFileSize++;
									this.initGetFileList();
									// 按照新的要求对文件列表进行排序
									this.sortFileList();
								}
							},
							(res) => {
								this.loading = false;
								this.complete = false;
								this.uploading = false;
								this.completeFileSize++;
							}, (cancelFunc) => {
								// 保存取消函数到文件项中
								fileItem.cancelToken = cancelFunc;
							}
						)
					);
				});
			},
			// 传输列表显示/隐藏
			transmissionList() {
				const uploadListDialog = document.getElementById('uploadListDialog');
				const hideButton = document.getElementById('hideButton');
				const displayButton = document.getElementById('displayButton');

				if (uploadListDialog.clientHeight > 0) {
					uploadListDialog.style.height = '0';
					hideButton.style.display = 'none';
					displayButton.style.removeProperty('display');
				} else {
					uploadListDialog.style.height = '60%';
					displayButton.style.display = 'none';
					hideButton.style.removeProperty('display');
				}
			},
			// 离线下载功能（省略详细实现）
			offlineDownload() {
				// 实现离线下载的逻辑
			},
			// 添加到影视库
			addToMovie() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
					return;
				}

				let dataForm = {
					fileId: this.selectedState[0],
				};
				addToMovie(dataForm, (res) => {
					const isSuccessful = res.code === 200 && res.success === true;
					if (isSuccessful) {
						Message.success('添加成功！');
						// 更新文件列表
						this.initGetFileList();
						this.cancelSelection();
					} else {
						Message.error(res.message);
					}
				});
			},
			// 从影视库移出
			removeOfMovie() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
					return;
				}

				let dataForm = {
					fileId: this.selectedState[0],
				};

				removeOfMovie(dataForm, (res) => {
					const isSuccessful = res.code === 200 && res.success === true;
					if (isSuccessful) {
						Message.success('成功移出！');
						// 更新文件列表
						this.initGetFileList();
						this.cancelSelection();
					} else {
						Message.error(res.message);
					}
				});
			},
			// 分享文件
			shareFiles(pageType) {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						fileIds: this.selectedState,
						isEncrypt: this.isEncrypt,
						validity: this.validity,
					};

					shareFiles(dataForm, (res) => {
						const isSuccessful = res.code === 200 && res.success === true;
						if (isSuccessful) {
							Message.success('创建成功！');
							this.centerDialogVisible = false;
							this.shareLink =
								`${window.location.origin}/cloud${config.url.share.getShareFiles}${res.data.shareId}?pageType=${pageType}`;
							this.shareFilesDialogVisible = true;

							if (this.isEncrypt) {
								this.verificationCode = res.data.code;
							} else if (this.multipleSelection.length === 1) {
								this.verificationCode =
									`wget -O ${this.multipleSelection[0].fileName} ${this.shareLink}`;
							} else {
								this.verificationCode = '';
							}
						} else {
							Message.error(res.message);
						}
					});
				}
			},
			// 复制分享链接及提取码
			copyShareLink() {
				var copyText = '链接：' + this.shareLink + ' 提取码：' + this.verificationCode;
				var input = document.createElement('input');
				input.value = copyText;
				document.body.appendChild(input);
				input.select();
				document.execCommand('Copy');
				input.className = 'oInput';
				input.style.display = 'none';
				Message.success('复制成功！');
			},
			// 复制分享链接
			copyShareLink1() {
				let copyText = '';
				if (this.multipleSelection.length === 1 && this.multipleSelection[0].isDir === 0) {
					copyText = '链接：' + this.shareLink + ' shell下载命令：' + this.verificationCode;
				} else {
					copyText = this.shareLink;
				}
				let input = document.createElement('input');
				input.value = copyText;
				document.body.appendChild(input);
				input.select();
				document.execCommand('Copy');
				input.className = 'oInput';
				input.style.display = 'none';
				Message.success('复制成功！');
			},
			//下载文件
			downloadFile() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件!');
				} else if (this.multipleSelection.length === 1 && this.multipleSelection[0].isDir === 0) {
					let file = this.multipleSelection[0];

					const link = document.createElement('a');
					link.href = `${config.url.file.downloadFile}${file.fileId}`;
					link.setAttribute('download', file.fileName);
					document.body.appendChild(link);
					link.click();

					//如果是单个文件
					/*axios({
						url: `${config.url.downloadFile}${file.fileId}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.fileName);
						document.body.appendChild(link);
						link.click();
					});*/

				} else {
					//如果是文件夹 或者 多个文件，则启动压缩下载
					let fileIdList = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						const file = this.multipleSelection[i];
						fileIdList.push(file.fileId);
					}

					let zipName = this.multipleSelection[0].fileName;
					if (fileIdList.length > 1) {
						zipName += "等多个文件";
					}


					axios({
						// url: `${config.url.folderDownload}${file.fileId}`,
						url: `${config.url.file.downloadFileZIP}?zipName=${zipName}&fileIdList=${fileIdList}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						let blob = new Blob([response.data], {
							type: 'application/zip'
						})
						let url = window.URL.createObjectURL(blob)
						const link = document.createElement('a')
						link.href = url
						link.download = zipName + '.zip';
						link.click()
						URL.revokeObjectURL(url)
					});
				}
			},
			// 双击打开文件夹或文件
			openDir(item) {
				if (item.isDir === 1) {
					// 清空选中文件
					this.cancelSelection();
					// 更新路径
					this.currentPathName += item.fileName + '/';
					this.currentPath = item.fileName;
					window.sessionStorage.setItem('path', this.currentPathName);
					this.initGetFileList();
				} else if (item.category === 3) {
					// 预览图片
					let imgUrl = config.url.file.downloadFile + item.fileId;
					this.imgList.push(imgUrl);
					this.showImgViewer();
				} else if (item.category === 1) {
					// 预览视频
					this.videoName = item.fileName;
					this.videoUrl = config.url.file.preview + item.fileId;
					this.playVideo();
				} else {
					switch (item.category) {
						case 0:
						case 2:
						case 4:
						case 6:
						case 8:
						case 10:
							if (item.suffix == 'other') return;
							var officeUrl = config.url.file.filePreview + item.fileId + '?fullfilename=' + item.fileName;
							window.open(
								'http://moyi.chengxy.cn:8012/onlinePreview?url=' + window.btoa(encodeURIComponent(
									officeUrl)),
								'_blank'
							);
							break;
					}
				}
			},
			// 显示图片预览
			showImgViewer() {
				this.imgViewerVisible = true;
				const preventDefault = (e) => {
					e.preventDefault();
				};
				document.body.style.overflow = 'hidden';
				document.addEventListener('touchmove', preventDefault, false);
			},
			// 关闭图片预览
			closeImgViewer() {
				this.imgViewerVisible = false;
				const preventDefault = (e) => {
					e.preventDefault();
				};
				document.body.style.overflow = 'auto';
				document.removeEventListener('touchmove', preventDefault, true);
				this.imgList = [];
			},
			// 播放视频
			playVideo() {
				this.dialogPlay = true;
			},
			// 关闭视频播放
			closeDialog() {
				this.videoUrl = '';
				this.videoName = '';
			},
			// 预览文件
			previewImg() {
				this.openDir(this.multipleSelection[0]);
			},
			// 创建新文件夹
			createFolderConfirm() {
				this.$prompt('请输入文件夹名称', '创建文件夹', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						inputPattern: /\S/,
						inputErrorMessage: '文件名不能为空',
					})
					.then(({
						value
					}) => {
						let data = {
							path: this.currentPathName,
							folderName: value,
						};
						createFolder(data, (result) => {
							if (result.code === 200) {
								this.initGetFileList();
								Message.success('新建成功！');
							} else {
								Message.error('新建失败');
							}
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '取消新建',
						});
					});
			},
			// 重命名文件
			rename() {
				if (this.multipleSelection.length !== 1) {
					this.$message.error('请选择单个文件！');
				} else {
					this.$prompt('请输入新文件名', '重命名', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							inputPattern: /\S/,
							inputErrorMessage: '文件名不能为空',
							inputValue: this.multipleSelection[0].fileName,
						})
						.then(({
							value
						}) => {
							if (value === this.multipleSelection[0].fileName) {
								Message.error('文件名未作修改，请重新输入！');
							} else {
								let dataForm = {
									fileName: value,
									fileId: this.multipleSelection[0].fileId,
								};
								rename(dataForm, (res) => {
									if (res.code === 200) {
										Message.success('修改成功！');
										this.initGetFileList();
										this.cancelSelection();
									} else {
										Message.error(res.message);
									}
								});
							}
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '取消重命名',
							});
						});
				}
			},
			// 删除选中文件
			deleteFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						fileIds: this.selectedState,
					};
					this.$confirm('此操作将文件移动到回收站, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						})
						.then(() => {
							deleteFile(dataForm, (res) => {
								if (res.code === 200 && res.success === true) {
									Message.success(res.message);
									this.initGetFileList();
									Utils.$emit('getMemory');
									this.cancelSelection();
								} else {
									Message.error(res.message);
								}
							});
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '已取消删除',
							});
						});
				}
			},
			// 移动选中文件
			moveFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					this.pasteStatus = 1;
					this.pasteFileIdList = [...this.selectedState];
				}
			},
			// 取消移动
			cencleMove() {
				this.pasteStatus = 0;
				this.pasteFileIdList = [];
			},
			// 粘贴文件
			pasteFiles() {
				if (this.pasteFileIdList.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						fileIds: this.pasteFileIdList,
						path: this.currentPathName,
					};
					this.$confirm('此操作将文件移动当前目录, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						})
						.then(() => {
							postRequest(config.url.file.moveFiles, dataForm, (res) => {
								if (res.code === 200 && res.success === true) {
									Message.success(res.message);
									this.initGetFileList();
									Utils.$emit('getMemory');
									this.cancelSelection();
									this.pasteStatus = 0;
									this.pasteFileIdList = [];
								} else {
									Message.error(res.message);
								}
							});
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '已取消移动',
							});
						});
				}
			},
			// 返回上一级目录
			lastPage() {
				this.cancelSelection();
				if (this.currentPathName.endsWith('/')) {
					this.currentPathName = this.currentPathName.slice(0, -1);
				}
				this.currentPathName = this.currentPathName.substring(0, this.currentPathName.lastIndexOf('/'));
				this.currentPath = this.currentPathName.substring(
					this.currentPathName.lastIndexOf('/') + 1,
					this.currentPathName.length
				);
				this.currentPathName += '/';
				if (this.currentPathName == '/') {
					this.currentPath = '根目录';
				}
				window.sessionStorage.setItem('path', this.currentPathName);
				this.initGetFileList();
			},
			// 返回根目录
			firstPage() {
				this.cancelSelection();
				this.currentPathName = '/';
				this.currentPath = '根目录';
				window.sessionStorage.setItem('path', this.currentPathName);
				this.initGetFileList();
			},
			// 菜单命令处理
			commandHandler(command) {
				if (command == 'logout') {
					// 执行退出登录操作
					router.replace('/');
				} else if (command == 'changePwd') {
					router.replace('/changePassword');
				} else if (command == 'personalCenter') {
					router.replace('/personalCenter');
				} else if (command == 'selectFile') {
					//选择文件
					this.isSelection = true;
				} else if (command == 'upload') {
					//上传文件
					this.toUpload();
				} else if (command == 'create') {
					//创建文件夹
					this.createFolderConfirm();
				}
			},
			sortFileList() {
				this.fileList.sort((a, b) => {
					if (a.isCompleted === b.isCompleted) {
						if (!a.isCompleted) {
							// 两个文件都在上传中，保持原有顺序
							return 0;
						} else {
							// 两个文件都已完成，按照完成时间倒序排列
							return b.completionTime - a.completionTime;
						}
					} else {
						// 正在上传的文件排在前面
						return a.isCompleted - b.isCompleted;
					}
				});
			},
			clearFileList() {
				if (this.fileList.filter(file => file.uploading).length > 0) {
					Message.warning({
						message: "文件正在上传中，无法清空"
					});
				} else {
					this.fileList = [];
					this.completeFileSize = 0;
				}
			},
			handleRemove(item) {
				if (!item.isCompleted) {
					// 文件正在上传中，弹出确认对话框
					this.$confirm('此操作将取消文件上传，是否继续？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						})
						.then(() => {
							// 用户确认取消上传
							this.cancelUpload(item);
						})
						.catch(() => {
							// 用户取消操作
							this.$message({
								type: 'info',
								message: '已取消操作',
							});
						});
				} else {
					// 文件已经上传完成，直接移除文件项
					this.removeFileItem(item);
				}
			},
			// 取消上传任务
			cancelUpload(item) {
				// 调用取消上传的方法
				if (item.cancelToken) {
					item.cancelToken(); // 取消请求
				}
				// 从文件列表中移除该文件项
				this.removeFileItem(item);
			},
			// 移除文件项
			removeFileItem(item) {
				// 从 fileList 中移除该文件项
				this.fileList = this.fileList.filter((file) => file.uid !== item.uid);
			},


		},
	};
</script>

<style>
	/* 样式部分 */
	.files-right-count {
		font-size: 1rem;
	}

	.mobile-files-main-list {
		height: 100vh;
		display: grid;
		grid-template-rows: auto auto 1fr;
	}

	.btn-group__wrapper {
		display: inline-block;
		vertical-align: middle;
	}

	.files-list-toolbar__wrapper {
		border-radius: 4px 4px 0 0;
		height: 40px;
		line-height: 40px;
		border-bottom-width: 0;
		overflow: hidden;
		padding: 6px 14px 0 12px;
		width: calc(100% - 20px);
	}

	.files-list-left__wrapper {
		float: left;
	}

	.files-list-left__wrapper .tittle {
		font-size: 1rem;
		color: #03081a;
		font-weight: 700;
	}

	.files-list-right__wrapper {
		float: right;
		color: #afb3bf;
		font-size: 1rem;
	}

	.nd-main-list__table {
		margin-left: -18px;
	}

	.nd-main-list__grid,
	.nd-main-list__table {
		height: calc(100% - 80px);
		width: calc(100% - 272px);
		display: inline-block;
	}

	.nd-main-list__detail,
	.nd-main-list__grid,
	.nd-main-list__table {
		min-height: 356px;
	}

	.nd-table {
		overflow: hidden;
	}

	.nd-detail {
		padding: 16px;
		display: inline-block;
		background: #f5f6fa;
		border-radius: 8px;
		font-size: 1rem;
		position: relative;
		overflow: auto;
	}

	.nd-main-list__detail {
		height: calc(100% - 190px);
		width: 272px;
		margin-left: 16px;
	}

	.nd-main-list__detail,
	.nd-main-list__grid,
	.nd-main-list__table {
		min-height: 356px;
	}

	.nd-detail__title {
		color: #03081a;
		font-weight: 600;
		padding-bottom: 15px;
	}

	.nd-detail__empty {
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		left: 50%;
		position: absolute;
		text-align: center;
	}

	.nd-detail__empty img {
		width: 60px;
		height: 60px;
	}

	.nd-detail__img {
		width: 240px;
		height: 240px;
		border-radius: 13px;
		position: relative;
	}

	.nd-detail__img.bg {
		background: #fff;
	}

	.nd-detail__img .category {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-height: 240px;
		max-width: 240px;
	}

	.nd-detail__props {
		color: #878c9c;
		padding: 10px;
	}

	.nd-detail__name {
		font-size: 14px;
		color: #454d5a;
		font-weight: 600;
		word-break: break-all;
		padding-top: 20px;
	}

	.nd-detail__props .prop_tittle {
		margin-top: 10px;
		font-size: 14px;
		color: #454d5a;
		font-weight: 600;
		word-break: break-all;
		text-align: left;
	}

	.nd-detail__props .prop_text {
		word-break: break-all;
		text-align: left;
	}

	.box {
		width: 100%;
		height: 100%;
	}

	.gap {
		width: 100%;
	}

	.tableBox {
		width: 100%;
		height: 100%;
		background: white;
	}

	.tableBox .mobile-grid {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}

	.el-table {
		height: 100%;
	}

	/* 格子布局样式 */
	.grid-item-mobile {
		float: left;
		width: 24%;
		height: 117px;
		margin: 0;
		border: 1px solid #fff;
		padding: 0px;
	}

	.grid-item-mobile.checked {
		border: 1px solid #90d8ff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}

	.grid-item-mobile:hover {
		border: 1px solid #fff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}

	.grid-item-mobile>img {
		width: 64px;
		height: 64px;
		margin: 5px;
		border-radius: 12px;
		object-fit: cover;
	}

	.grid-item-mobile>span {
		padding: 0 10px;
		font-size: 0.87rem;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		line-height: 1.2;
		white-space: normal;
		word-break: break-all;
	}

	/* 上传列表样式 */
	.mobile-upload-list-dialog {
		width: 100%;
		height: 0;
		position: absolute;
		bottom: -20px;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		box-shadow: 0 0 10px #ccc;
		overflow: hidden;
		transition: height 0.3s ease-in-out;
	}

	.mobile-upload-list-dialog .dialog-header {
		padding-top: 15px;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		background-color: #fff;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #666;
		border-bottom: 1px solid #f7faff;
		text-indent: 1em;
		font-size: 14px;
		height: 44px;
	}

	.mobile-upload-list-dialog .item {
		margin: 0 15px;
	}

	.mobile-file-info {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		padding: 10px 0;
	}

	.mobile-file-info>div {}

	.mobile-file-info .img {}

	.mobile-file-info .file-name {
		text-align: left;
		padding: 0 10px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		line-height: 1.2;
		white-space: normal;
		word-break: break-all;
	}

	.mobile-size {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.mobile-size-load {
		font-family: 'Courier New', monospace;
		/* 使用等宽字体 */
		min-width: 120px;
		/* 根据需要设置最小宽度 */
		display: inline-block;
		text-align: right;
		/* 可选 */
	}

	.mobile-size-time {
		font-size: 0.8rem;
	}

	.mobile-size-speed {
		font-size: 0.9rem;
		color: green;
	}

	.mobile-file-info .operation {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15%;
	}

	.file-progress {
		width: 100%;
	}

	.dialog-body {
		font-family: 'Microsoft YaHei', SimSun;
		background-color: white;
		font-size: 12px;
		color: #424e67;
		overflow: scroll;
		height: 100%;
	}

	.wp-share-file {
		margin-top: 8px;
	}

	.wp-share-file_dialog-footer {
		text-align: center;
		margin-top: 24px;
		font-weight: 700;
	}

	.grid-item-mobile {
		position: relative;
	}

	.m-play-button-overlay {
		position: absolute;
		top: 36px;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	.movie-overlay {
		position: absolute;
		top: 58px;
		right: 26px;
		pointer-events: none;
	}

	.m-play-button-overlay>img {
		width: 32px;
		height: 32px;
	}

	.longPressDiv {
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
	}

	.longPressDiv>div {
		padding-top: 10px;
	}

	.cancelSelect,
	.allSelect {
		color: #409eff;
		font-size: 0.9rem;
	}

	.el-dropdown-menu.el-popper {
		top: 30px !important;
	}

	.moblie-more {
		display: flex;
		justify-content: space-between;
	}

	.moblie-more>div {
		padding-top: 10px;
	}

	.selectSize {
		font-size: 1.1rem;
		font-weight: 800;
		color: #000;
	}

	.file-button-group>.el-button {
		width: 25%;
	}

	.userInfo.el-dropdown {
		padding-top: 0;
	}

	.list-move-enter-active,
	.list-move-leave-active {
		transition: all 0.5s;
	}

	.list-move-enter,
	.list-move-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}

	.list-move-move {
		transition: transform 0.5s;
	}

	.mobile-memory {
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		box-shadow: 0 0 10px #ccc;
	}
</style>